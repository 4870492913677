<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CURRENCY_TYPES } from './store'
import { ROUTES as CURRENCY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import CurrencyTable from './CurrencyTable'
import CurrencyCommonFilters from './CurrencyCommonFilters'
import CurrencyEdit from './CurrencyEdit.vue'

export default {
  name: 'CurrencyList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': CurrencyTable,
    'common-filters': CurrencyCommonFilters,
    'component-edit': CurrencyEdit,
    'component-detail': CurrencyEdit
  },
  data () {
    return {
      CURRENCY_ROUTES,
      title: this.$t('Currencies'),
      rolePerm: 'currency_currency_list',
      actionEnablePermission: ['currency_currency_enable'],
      actionDisablePermission: ['currency_currency_disable'],
      fieldName: 'code',
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 3 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 3 },
        { key: 'filter__slug__icontains', placeholder: this.$t('Slug'), type: 'text', col: 3 },
        { key: 'filter__symbol__icontains', placeholder: this.$t('Symbol'), type: 'text', col: 3 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CURRENCY_TYPES.GENERIC.currency.currency.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.CURRENCY_ROUTES.CURRENCY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('currency_currency_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CURRENCY_TYPES.GENERIC.currency.currency.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CURRENCY_TYPES.GENERIC.currency.currency.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CURRENCY_TYPES.GENERIC.currency.currency.LIST.ACTIONS
    })
  }
}
</script>
